/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../components/Layout/Layout';

const ImpactPage = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Privacy Policy | Presidium Health - Home-based Healthcare"
        description="Presidium Health provides home-based care services to patients."
        metaTags={[
          {
            name: 'keywords', 
            content: 'home-based healthcare, value-based healthcare, patient care, telemedicine'
          }
        ]}
      />

      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <h1 className="text-primary fw-bold fs-2 text-center mb-5">
                Presidium Health Website Privacy Policy
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center mb-5">
            <Col lg={10}>
              <p className="fs-4">Last revised: March 2024</p>
              <p>
                Presidium Health Company is committed to protecting your
                privacy. This Privacy Policy explains how your personal
                information is collected, used and disclosed by Company. This
                Privacy Policy applies to our website PresidiumHealth.com, and
                applications (and any successor website or applications operated
                by or on behalf of the Company), as well as any product,
                service, content, tools, features and functionality offered on
                or through our website (collectively, our <b>“Services”</b>). By
                accessing or using our Services, you signify that you have read,
                understood and agree to our collection, storage, use and
                disclosure of your personal information as described in this
                Privacy Policy and our Terms of Service.
              </p>
              <p>
                <b>1. WHAT INFORMATION DO WE COLLECT AND FOR WHAT PURPOSE?</b>
              </p>
              <p>The categories of information we collect may include:</p>
              <p>
                <b>Information you provide to us directly.</b>
              </p>
              <p>
                <b>If you are utilizing our Services:</b> we may collect
                personal information, such as personal health information. The
                supply of such information to Company is at your discretion. We
                may also collect any communications between you and Company and
                any other information you provide to Company.
              </p>
              <p>
                We use the information that we collect from you to provide you
                with the Services, provide customer support, to communicate with
                you and to enhance your use of the Services. We will also use
                your contact information to communicate with you in response to
                your inquiries, to provide the services you request, and to
                manage your account. We will communicate with you by your email
                address or telephone number in your user profile, in accordance
                with your wishes. Message frequency varies per user. Message and data rates may apply. Text HELP for help. Text STOP to unsubscribe. Carriers are not liable for delayed or undelivered messages. 
                By providing a telephone number, you consent
                to us contacting you at that number for the purposes outlined in
                this Privacy Policy. You may revoke your consent to be contacted
                at any time by making the change on our user information page or
                by emailing us at privacy@presidiumhealth.com.
              </p>
              <p>
                <b>Data collected through the use of the Services.</b> We
                collect information about how you use the Services, your actions
                on the Services, and content you post to the Services, and any
                content you provide through the Services <b>(“User Content”)</b>
                . Please remember that Company may, but has no obligation to,
                monitor, record, and store User Content in order to protect your
                safety or the safety of other users, to assist with regulatory
                or law enforcement efforts, or to protect and defend our rights
                and property. By using the Services, you consent to the
                recording, storage and disclosure of such communications you
                send or receive for these purposes.
              </p>
              <p>
                <b>
                  Information we receive from third party sites you connect to
                  our Services.
                </b>{' '}
                We may receive personal information about you from third parties
                and combine that with information we collect through our
                Services. For example, we may obtain: first name, last name,
                title, preferred pronoun, date of birth, gender, username or
                similar identifier, password, photographs, video images, billing
                address, delivery address, email address and telephone numbers,
                as well as details of any additional persons for whom copy
                correspondence is required, preferences such as languages,
                survey information, internet protocol (IP) address, your login
                data, browser type and version, time zone setting and location,
                browser plug-in types and versions, operating system and
                platform and other technology on the devices you use to access
                our applications, information about how you use our website,
                products and services (including the data obtained from cookies,
                web logs and other similar technologies that monitor the use of
                our applications), details of your correspondence with us
                (including bidding instructions recorded online or over the
                telephone, and any complaints you have made to our customer
                services team). The data we receive from these third party sites
                is dependent upon that third party’s policies and your privacy
                settings on that third party site. You should always review, and
                if necessary, adjust your privacy settings on third party
                websites and services before linking or connecting them to our
                Services.
              </p>
              <p>
                <b>Location Information.</b> We collect information about your
                location through GPS, WiFi, wireless network triangulation or
                other method in order to obtain your location for the purposes
                of providing our Services. We may also approximate your location
                by your IP Address. We also use the Google Maps API to gather
                information about your location. Google uses various
                technologies to determine your location, including IP address,
                GPs and other sensors that may, for example, provide Google with
                information on nearby devices, WiFi access points and cell
                towers (see Google Maps’ privacy policy to learn more). We
                maintain location information only so long as is reasonable to
                provide the Services and then delete location data tied to your
                personal information. We may maintain de-identified location
                data for a longer period of time in order to analyze aggregate
                trends and metrics.
              </p>
              <p>
                We use this information to operate, maintain, and provide to you
                the features and functionality of the Services; to process and
                complete transactions that you request on the Services; to
                verify your identity; to monitor and analyze Services usage and
                trends; as well as to request feedback or communicate directly
                with you, such as to send you email messages and push
                notifications, and permit you to communicate with others on the
                Services. We may also send you Services-related emails or
                messages (e.g., account verification, change or updates to
                features of the Services, technical and security notices). For
                more information about your communication preferences, see
                <b>“Control Over Your Information”</b> below.
              </p>
              <p>
                We may de-identify information collected from and about you and
                use and disclose such de-identified data for any purpose.
              </p>
              <p>
                <b>
                  2. HOW WE USE COOKIES AND OTHER TRACKING TECHNOLOGY TO COLLECT
                  INFORMATION
                </b>
              </p>
              <p>
                We, and our third party partners, automatically collect certain
                types of usage information when you visit our Services, read our
                emails, or otherwise engage with us. We typically collect this
                information through a variety of tracking technologies,
                including cookies, web beacons, embedded scripts,
                location-identifying technologies, file information and similar
                technology (collectively, <b>“tracking technologies”</b>). For
                example, we collect information about your device and its
                software, such as your IP address, browser type, Internet
                service provider, platform type, device type, operating system,
                date and time stamp, a unique ID that allows us to uniquely
                identify your browser, mobile device or your account, and other
                such information. We also collect information about the way you
                use our Services, for example, the site from which you came and
                the site to which you are going when you leave our website, the
                pages you visit, the links you click, how frequently you access
                the Services, whether you open emails or click the links
                contained in emails, whether you access the Services from
                multiple devices, and other actions you take on the Services.
                When you access our Services from a mobile device, we may
                collect unique identification numbers associated with your
                device or our mobile application mobile carrier, device type,
                model and manufacturer, mobile device operating system brand and
                model, phone number, and depending on your mobile device
                settings, your geographical location data, including GPS
                coordinates (e.g., latitude and/or longitude) or similar
                information regarding the location of your mobile device, or we
                may be able to approximate a device’s location by analyzing
                other information, like an IP address. We may collect analytics
                data, or use third-party analytics tools such as Google
                Analytics, to help us measure traffic and usage trends for the
                Services and to understand more about the demographics of our
                users. You can learn more about Google’s practices at
                http://www.google.com/policies/privacy/partners, and view its
                currently available opt-out options at
                https://tools.google.com/dlpage/gaoptout.
              </p>
              <p>
                Although we do our best to honor the privacy preferences of our
                users, we are unable to respond to Do Not Track signals set by
                your browser at this time.
              </p>
              <p>
                We use or may use the data collected through tracking
                technologies to: (a) remember information so that you will not
                have to re-enter it during your visit or the next time you visit
                the Services; (b) provide custom, personalized content and
                information; (c) provide and monitor the effectiveness of our
                Services; (d) monitor aggregate metrics such as total number of
                visitors, traffic, usage, and demographic patterns on our
                Services; (e) diagnose or fix technology problems; and (f)
                otherwise to plan for and enhance our Services.
              </p>
              <p>
                If you would prefer not to accept cookies, most browsers will
                allow you to: (i) change your browser settings to notify you
                when you receive a cookie, which lets you choose whether or not
                to accept it; (ii) disable existing cookies; or (iii) set your
                browser to automatically reject cookies. Please note that doing
                so may negatively impact your experience using the Services, as
                some features and services on our Services may not work
                properly. Depending on your mobile device and operating system,
                you may not be able to delete or block all cookies. You may also
                set your e-mail options to prevent the automatic downloading of
                images that may contain technologies that would allow us to know
                whether you have accessed our e-mail and performed certain
                functions with it.
              </p>
              <p>
                <b>3. SHARING OF YOUR INFORMATION</b>
              </p>
              <p>
                We may share your personal information in the instances
                described below. For further information on your choices
                regarding your information, see the{' '}
                <b>“Control Over Your Information”</b> section below. To the
                extent we create, receive, maintain or transmit protected health
                information as a <b>“covered entity”</b> or on behalf of a{' '}
                <b>“covered entity”</b> as a <b>“business associate”</b> as such
                terms are defined under HIPAA, we will only use and disclose
                your <b>“protected health information”</b> as permitted under
                HIPAA. To the extent permitted under applicable laws, we may
                share your personal information with:
              </p>
              <p>
                <b>Third parties at your request.</b> For example, you may have
                the option to share your activities on the Services with your
                friends through email, text or on various social media sites;
              </p>
              <p>
                <b>Other companies and brands owned or controlled by Company</b>
                , including but not limited to other companies owned by or under
                common ownership as Company, which also includes our
                subsidiaries (i.e., any organization we own or control) or our
                ultimate holding company (i.e., any organization that owns or
                controls us) and any subsidiaries it owns. These companies will
                use your personal information in the same way as we can under
                this Privacy Policy;
              </p>
              <p>
                <b>
                  Third-party vendors and other service providers that perform
                  services on our behalf
                </b>
                , as needed to carry out their work for us, which may include
                processing payments, providing mailing services, providing tax
                and accounting services, web hosting, or providing analytic
                services; no mobile information will be shared with third
                parties/affiliates for solely marketing/promotional purposes;
              </p>
              <p>
                <b>
                  The public when you provide feedback or User Content on our
                  Services.
                </b>{' '}
                For example, if you post User Content on our blog or comment on
                our social media sites, your information, such as your username
                and your comments, may be displayed on our website or on our
                social media pages;
              </p>
              <p>
                <b>Other parties in connection with a company transaction</b>,
                such as a merger, sale of company assets or shares,
                reorganization, financing, change of control or acquisition of
                all or a portion of our business by another company or third
                party, or in the event of a bankruptcy or related or similar
                proceedings; and{' '}
              </p>
              <p>
                <b>
                  Third parties as required by law or subpoena or if we
                  reasonably believe that such action is necessary to
                </b>{' '}
                (a) comply with the law and the reasonable requests of law
                enforcement; (b) to enforce our Terms of Services or to protect
                the security or integrity of our Services; and/or (c) to
                exercise or protect the rights, property, or personal safety of
                Company, our visitors, or others.
              </p>
              <p>
                We may also share information with others in an aggregated or
                otherwise anonymized form that does not identify you as an
                individual, to the extent permitted under applicable laws.
              </p>
              <p>
                <b>4. CONTROL OVER YOUR INFORMATION</b>
              </p>
              <p>
                <b>Profile and Data Sharing Settings.</b> You may update your
                profile information, such as your username, and may change some
                of your data sharing preferences by visiting{' '}
                <b>“Edit Profile”</b> in your account.
              </p>
              <p>
                <b>Access to your Device Information.</b> You may control the
                Services’ access to your device information through your
                <b>“Settings”</b> app on your device.
              </p>
              <p>
                <b>How to control your communications preferences:</b> You can
                stop receiving promotional email communications from us by
                clicking on the <b>“unsubscribe link”</b> provided in such
                communications. We make every effort to promptly process all
                unsubscribe requests. You may not opt out of service-related
                communications (e.g., account verification, transactional
                communications, changes/updates to features of the Services,
                technical and security notices).
              </p>
              <p>
                <b>Modifying or deleting your information:</b> If you have any
                questions about reviewing, modifying, or deleting your
                information, or if you want to remove your name or comments from
                our website or publicly displayed content, you can contact us
                directly at <b>privacy@presidiumhealth.com</b>. We may not be
                able to modify or delete your information in all circumstances.
              </p>
              <p>
                <b>5. HOW WE STORE AND PROTECT YOUR INFORMATION</b>
              </p>

              <p>
                <b>Data storage and transfer:</b> Your information collected
                through our website may be stored and processed in the United
                States or any other country in which Company or its affiliates
                or service providers maintain facilities.
              </p>
              <p>
                <b>Keeping your information safe:</b> We care about the security
                of your information and employ certain physical, administrative,
                and technological safeguards designed to preserve the integrity
                and security of all information collected through our website.
                However, no security system is impenetrable and we cannot
                guarantee the security of our systems 100%.
              </p>
              <p>
                <b>6. CHILDREN’S PRIVACY</b>
              </p>
              <p>
                Company does not knowingly collect or solicit any information
                from anyone under the age of 13 on this Services. In the event
                that we learn that we have inadvertently collected personal
                information from a child under age 13, we will delete that
                information as quickly as possible. If you believe that we might
                have any information from a child under 13, please contact us at <b>privacy@presidiumhealth.com</b>.
              </p>
              <p>
                <b>7. LINKS TO OTHER WEBSITES AND SERVICES</b>
              </p>
              <p>
                The Services may contain links to and from third party websites
                of our business partners, advertisers, and social media sites
                and our users may post links to third party websites. If you
                follow a link to any of these websites, please note that these
                websites have their own privacy policies and that we do not
                accept any responsibility or liability for their policies. We
                strongly recommend that you read their privacy policies and
                terms and conditions of use to understand how they collect, use,
                and share information. We are not responsible for the privacy
                practices or the content on the websites of third party sites.
              </p>
              <p>
                <b>8. HOW TO CONTACT US</b>
              </p>
              <p>
                If you have any questions about this Privacy Policy or the
                website, please contact us at <b>privacy@presidiumhealth.com</b>
                .
              </p>
              <p>
                <b>9. CHANGES TO OUR PRIVACY POLICY</b>
              </p>
              <p>
                We may modify or update this Privacy Policy from time to time to
                reflect the changes in our business and practices, and so you
                should review this page periodically. When we change the policy
                in a material manner we will let you know and update the ‘last
                modified’ date at the bottom of this page. If you object to any
                changes, you may close your account. Continuing to use our
                Services after we publish changes to this Privacy Policy means
                that you are consenting to the changes.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ImpactPage;
